<template>
  <div>
    <div class="global-container-flex">
      <sidebar />
      <div :style="{ width: screenWidth + 'px' }" class="global-container-content-full-width">
        <navbar />
        <div class="v-container-search-bar" style="display: flex; flex-direction: column;">
          <div class="global-container-router-view" :style="{ height: screenHeight + 'px' }">
            <div class="v-container-search-bar mx-3 my-5">
              <div class="titleText" style="display: flex; align-items: center; flex-wrap: wrap; justify-content: start;">
                <p class="mon-bold f-s45 l-spacing0 mb-0">
                  {{ "Proplat Calculator" }}
                </p>
              </div>
            </div>
            <v-container>
              <v-card v-if="!loadings.calculator" class="mx-3 mt-2">
                <v-row class="pa-4" style="margin: auto; justify-content: space-evenly;">
                  <v-chip
                    v-for="(chip, index) in aWarehouses"
                    :key="index"
                    large
                    :class="{ 'active-chip': chip.active }"
                    @click="toggleChip(index)"
                  >
                    {{ chip.sName }}
                  </v-chip>
                  <!-- <v-autocomplete
                    :items="aWarehouses"
                    v-model="selectedWarehouse"
                    @change="getTemporalShoppingCart(true)"
                    hide-details
                    chips
                    :loading="loadings.loadingWarehouseAndPayment"
                    :disabled="loadings.loadingWarehouseAndPayment"
                    label="Warehouse"
                    item-text="sName"
                    item-value="sBorderWarehouseId"
                    style="max-width: 300px;"
                    >
                  </v-autocomplete> -->
                </v-row>
                <v-form ref="myInput" class="mx-3 py-8" style="display: flex; justify-content: space-evenly; flex-wrap: wrap;" v-on:submit.prevent lazy-validation>
                  <div style="width: 300px">
                    <div class="d-flex pa-2" style="align-items: end;">
                      <h3 class="mr-3" style="width: 140px;">Take Rate: </h3>
                      <v-text-field
                        hide-details
                        style="width: 50%; margin: auto"
                        prefix=""
                        suffix="%"
                        v-model="product.sTakeRate"
                        :value="product.sTakeRate"
                        :rules="[rules.required]"
                      >
                      </v-text-field>
                    </div>
                    <div class="d-flex pa-2" style="align-items: end;">
                      <h3 class="mr-3" style="width: 140px;">Flete: </h3>
                      <v-text-field
                        hide-details
                        style="width: 50%; margin: auto"
                        prefix="$ "
                        suffix="MXN"
                        :value="product.dFreight"
                        v-model="product.dFreight"
                        :rules="[rules.required, rules.greaterThanMinusOne]"
                      >
                      </v-text-field>
                    </div>
                    <div class="d-flex pa-2" style="align-items: end;">
                      <h3 class="mr-3" style="width: 140px;">Comisión Flete: </h3>
                      <v-text-field
                        hide-details
                        style="width: 50%; margin: auto"
                        prefix=""
                        suffix="%"
                        :value="product.dFreightPercent"
                        v-model="product.dFreightPercent"
                        :rules="[rules.required, rules.greaterThanMinusOne]"
                      >
                      </v-text-field>
                    </div>
                    <div class="d-flex pa-2" style="align-items: end;">
                      <h3 class="mr-3" style="width: 140px;">In & Out: </h3>
                      <v-text-field
                        hide-details
                        style="width: 50%; margin: auto"
                        prefix="$ "
                        suffix="USD"
                        :value="product.sInOutRate"
                        v-model="product.sInOutRate"
                        :rules="[rules.required, rules.greaterThanMinusOne]"
                      >
                      </v-text-field>
                    </div>
                    <div class="d-flex pa-2" style="align-items: end;">
                      <h3 class="mr-3" style="width: 140px;">Aduana: </h3>
                      <v-text-field
                        hide-details
                        style="width: 50%; margin: auto"
                        prefix="$ "
                        suffix="USD"
                        :value="product.sCustomsFee"
                        v-model="product.sCustomsFee"
                        :rules="[rules.required, rules.greaterThanMinusOne]"
                      >
                      </v-text-field>
                    </div>
                    <div class="d-flex pa-2" style="align-items: end;">
                      <h3 class="mr-3" style="width: 140px;">Tipo de cambio: </h3>
                      <v-text-field
                        hide-details
                        style="width: 50%; margin: auto"
                        prefix="$ "
                        suffix="MXN"
                        :value="product.dRatePerUSD"
                        v-model="product.dRatePerUSD"
                        :rules="[rules.required, rules.greaterThanMinusOne]"
                      >
                      </v-text-field>
                    </div>

                    <div class="d-flex pa-2" style="align-items: end;">
                      <h3 class="mr-3" style="width: 140px;">Costos aduanales: </h3>
                      <v-text-field
                        hide-details
                        style="width: 50%; margin: auto"
                        prefix="$ "
                        suffix="USD"
                        :value="product.dInspectionCost"
                        v-model="product.dInspectionCost"
                        :rules="[rules.required, rules.greaterThanMinusOne]"
                      >
                      {{ product.dInspectionCost }}
                      </v-text-field>
                    </div>
                  </div>
                  <div style="display: flex; flex-direction: column; justify-content: space-around; width: 300px">
                    <div>
                      <div class="d-flex pa-2" style="align-items: end;">
                        <h3 class="mr-3" style="width: 140px;">Cajas por pallet: </h3>
                        <v-text-field
                          hide-details
                          style="width: 50%; margin: auto"
                          prefix=""
                          suffix=""
                          :value="product.dBoxesPerPallet"
                          v-model="product.dBoxesPerPallet"
                          :rules="[rules.required, rules.greaterThanZero]"
                        >
                        </v-text-field>
                      </div>
                      <div class="d-flex pa-2" style="align-items: end;">
                        <h3 class="mr-3" style="width: 140px;">Número de pallets: </h3>
                        <v-text-field
                          hide-details
                          style="width: 50%; margin: auto"
                          prefix=""
                          suffix=""
                          :value="product.sTotalPallets"
                          v-model="product.sTotalPallets"
                          :rules="[rules.required, rules.greaterThanZero]"
                        >
                        </v-text-field>
                      </div>
                      <div class="d-flex pa-2" style="align-items: end;">
                        <h3 class="mr-3" style="width: 140px;">Factoraje: </h3>
                        <v-text-field
                          hide-details
                          style="width: 50%; margin: auto"
                          prefix=""
                          suffix="%"
                          :value="parseFloat(product.dFinance, 2)"
                          v-model="product.dFinance"
                          :rules="[rules.required, rules.greaterThanMinusOne]"
                        >
                        </v-text-field>
                      </div>
                      <div class="d-flex pa-2" style="align-items: end;">
                        <h3 class="mr-3" style="width: 140px;">MXN bruto: </h3>
                        <v-text-field
                          hide-details
                          disabled
                          style="width: 50%; margin: auto"
                          prefix="$ "
                          suffix="MXN"
                          :value="product.GrossPrice"
                          v-model="product.GrossPrice"
                          :rules="[rules.required, rules.greaterThanMinusOne]"
                        >
                        </v-text-field>
                      </div>
                      <div class="d-flex pa-2" style="align-items: end;">
                        <h3 class="mr-3" style="width: 140px;">MXN neto: </h3>
                        <v-text-field
                          hide-details
                          style="width: 50%; margin: auto"
                          prefix="$ "
                          suffix="MXN"
                          :value="product.dUnitPrice"
                          v-model="product.dUnitPrice"
                          :rules="[rules.required, rules.greaterThanMinusOne]"
                        >
                        </v-text-field>
                      </div>
                    </div>
                    <div>
                      <div class="d-flex content-shadow gradient-button mb-3 pa-0" style="align-items: center;">
                        <v-btn
                          @click="calculatePrice()"
                          depressed
                          block
                          class=""
                          color="transparent"
                        >
                          {{ "Calcular" }}
                        </v-btn>
                      </div>
                      <div v-if="!loadings.loadingFOB" class="d-flex pa-4 content-shadow background3194FF" style="align-items: center;">
                        <h3 class="mr-3" style="width: 140px;">FOB {{ selectedInOut }}: </h3>
                        <h3>  {{ "$ " + product.priceFOB + " USD" }}</h3>
                      </div>
                      <div v-else class="d-flex pa-4 content-shadow background3194FF" style="align-items: center;">
                        <h3 class="mr-3" style="width: 140px;">FOB {{ selectedInOut }}: </h3>
                        <v-progress-circular
                          indeterminate
                          color="black"
                        ></v-progress-circular>
                      </div>
                    </div>
                  </div>
                </v-form>
              </v-card>
              <v-skeleton-loader
                  v-else
                  class="mx-auto"
                  max-width="800"
                  type="card"
                ></v-skeleton-loader>
            </v-container>
          </div>
        </div>
      </div>
    </div>
    <v-dialog v-model="dialogAddProduct" persistent max-width="1200px">
      <v-card>
        <div style="text-align: end;">
          <v-btn class="pa-0" text @click="cancel()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-title class="pa-5">
          Artículos
          <v-spacer></v-spacer>
          <div style="display: flex; gap: 20px;">
            <v-text-field
              v-model="search"
              class="pt-0 mt-0"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </div>
        </v-card-title>
        <v-card-text class="pt-6">
          <v-data-table
            fixed-header
            height="300px"
            show-select
            v-model="selected"
            item-key="sProductId"
            :headers="headersExcel"
            :loading="loadings.loadingProductsTable"
            :items="tableData"
            :search="search"
            :disable-sort="true"
          >
            <template v-slot:item.aImages="{ item }">
              <img
                :src="item.aImages"
                alt="Product Image"
                style="width: 140px; height: 140px; margin: 5px; border-radius: 15px; margin-bottom: 0px;"
              />
            </template>
            <template v-slot:item.dUnitPrices="{ item }">
              <span> {{ "$" + item.dUnitPrices + " MXN" }} </span>
            </template>
            <template v-slot:item.dUnitPrice="{ item }">
              <span> {{ "$" + item.dUnitPrice + " USD" }} </span>
            </template>

            <template v-slot:item.oMeasurementUnit="{ item }">
              <span> {{ item.oMeasurementUnit.sName }} </span>
            </template>
            <template v-slot:item.bVisible="{ item }">
              <v-chip v-if="item.bVisible" outlined small class="ma-2" color="green">
                Activo
                <!-- <v-icon class="ml-1" style="font-size: 20px;">
                      mdi-play-circle-outline
                    </v-icon> -->
              </v-chip>
              <v-chip v-else class="ma-2" outlined small color="#d8686b">
                Pausado
                <!-- <v-icon style="font-size: 20px;">
                      mdi-pause
                    </v-icon> -->
              </v-chip>
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions class="pb-5">
          <v-spacer></v-spacer>
          <v-btn :disabled="selected.length == 0" depressed class="gradient-button" color="transparent" @click="addProduct()">
            {{ "Add items" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDeleteProduct" persistent max-width="350px">
      <div class="container-dialog">
        <div class="display-flex align-items-center justify-content-center" style="text-align: center;">
          <img width="90px" src="@/assets/icons/delete.png" alt="" />
        </div>
        <p class="text-title mon-bold">{{ "Eliminar artículo" }}</p>
        <div class="display-flex align-items-center justify-content-center">
          <p class="text-description mon-regular">
            {{ "¿Estás seguro de eliminar este elemento? Al realizar esta acción no podrá revertirse." }}
          </p>
        </div>
        <div class="display-flex align-items-center justify-content-center mt-9">
          <v-btn class="button-cancel mon-regular" elevation="0" @click="dialogDeleteProduct = false">
            {{ "Cancelar" }}
          </v-btn>
          <v-btn class="button-delete mon-bold ml-5" elevation="0" @click="deleteProduct()">
            {{ "Eliminar" }}
          </v-btn>
        </div>
      </div>
    </v-dialog>
    <!-- <v-overlay style="z-index: 99999999;" :value="loadings.loadingAddProduct">
      <div style="display: flex; flex-direction: column; width: 200px;align-items: center;">
        <v-progress-circular :size="50" color="black" indeterminate></v-progress-circular>
        <span class="mt-3">Loading...</span>
      </div>
    </v-overlay> -->
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import moment from "moment-timezone";

export default {
  name: "Articles",
  data() {
    return {
      search: "",
      iCurrentPage: 1,
      overlay: false,
      iItemsPerPage: 20,
      iNumPages: 0,
      iTotalItems: 0,
      sSearch: "",
      sCategoryId: "",
      aCategory: [],
      sSubCategoryId: "",
      checkbox: false,
      aSubCategory: [],
      chips: [
        { label: 'Chip 1', active: true },
        { label: 'Chip 2', active: false },
        { label: 'Chip 3', active: false }
      ],
      SalesOrderFees: [],
      //
      heightTable: 0,
      bHeightTable: 0,
      screenWidth: 0,
      screenHeight: 0,
      texts: "",
      e1: 1,
      dialogAddProduct: false,
      selected: [],
      selectedArtcilesToList: [],
      exchangeRate: "",
      rules: {
        required: value => !!value || 'Required.',
        greaterThanZero: value => value > 0 || 'Must be greater than zero.',
        greaterThanMinusOne: value => value > -1 || 'Must be greater than zero.'
      },
      snack: false,
      snackColor: "",
      snackText: "",
      data: [],
      results: [],
      headers: [
        { text: "Producto", value: "sProductName", align: "center" },
        { text: "Cajas", value: "dQuantity", align: "center" },
        { text: "Cajas por pallet", value: "dBoxesPerPallet", align: "center" },
        { text: "Precio MXN", value: "dUnitPrice", align: "center" },
        { text: "Precio USD", value: "dUnitPriceUSD", align: "center" },
        { text: "Subtotal productos", value: "dSubTotalPrice", align: "center" },
        { text: "Costo de flete", value: "dFreightPerBox", align: "center" },
        { text: "Costo de Aduana", value: "sCustomPerBox", align: "center" },
        { text: "Costo de in&Out", value: "dTotalInOutPerBox", align: "center" },
        { text: "Precio por caja", value: "sTotalPerBox", align: "center" },
        { text: "Total con costo operacional", value: "sTotal", align: "center" },
      ],
      headersProducto: [
        { text: "Producto", value: "sProductName", align: "center" },
        { text: "Cajas", value: "dQuantity", align: "center" },
        { text: "Boxes per pallet", value: "dBoxesPerPallet", align: "center" },
        { text: "Total boxes", value: "sTotalBoxes", align: "center" },
        { text: "Precio MXN", value: "dUnitPrice", align: "center" },
        { text: "Take Rate", value: "sTakeRate", align: "center" },
        { text: "Precio USD", value: "dUnitPriceUSD", align: "center" },
        { text: "Subtotal productos", value: "dSubTotalPrice", align: "center" },
        { text: "Acciones", value: "actions", align: "center" },
      ],
      headersLogistica: [
        { text: "Costo de flete", value: "dFreightPerBox", align: "center" },
        { text: "Costo de Aduana", value: "sCustomPerBox", align: "center" },
        { text: "Costo de in&Out", value: "dTotalInOutPerBox", align: "center" },
        { text: "Costo Logistico", value: "sLogisticCost", align: "center" },
        { text: "Precio por caja", value: "sTotalPerBox", align: "center" },
        { text: "Total con costo operacional", value: "sTotal", align: "center" },
      ],
      headersExcel: [
        { text: "Producto", value: "aImages", align: "center" },
        { text: "Nombre", value: "sName" },
        { text: "Proveedor", value: "sVendorEnterpriseName" },
        { text: "Tipo de caja", value: "oMeasurementUnit" },
        { text: "Inventario", value: "dInventoryQuantity" },
        { text: "Fecha disponible", value: "tProductAvailableTime" },
        { text: "Estado", value: "bVisible", align: "center" },
      ],
      headersComisiones: [
        {
          text: "Nombre",
          value: "sFeeName",
          cellClass: "mon-regular f-s12 color000000",
          class: "f-s14",
        },
        {
          text: "Subtotal de comisiones",
          value: "dTotal",
          cellClass: "mon-bold f-s12 color000000 text-end background3194FF",
          class: "f-s14 background3194FF",
          align: "center",
        },
      ],
      selectedCustomer: null,
      selectedWarehouse: null,
      selectedPayment: null,
      tableData: [],
      excelTableData: [],
      loadings: {
        loadingFOB: false,
        calculator: false,
      },
      showShoppingCart: false,
      customers: [],
      dialogEditProduct: false,
      dialogEditFeesPrice: false,
      dialogEditFinalPrice: false,
      dialogEditExchangeRate: false,
      dialogDeleteProduct: false,
      forms: {
        update: {},
        delete: {},
      },
      reportTypeGeneral: "",
      file: null,
      aWarehouses: [],
      today: "",
      product: {},
      clientProduct: {},
      selectedInOut: ""
    };
  },
  beforeMount() {
    this.texts = FILE.ordersTexts[this.selectLanguage];
    this.getWarehouses();
    this.getCalculator();
    this.today = moment().format("DD-MM-YYYY");
  },
  created() {
    window.addEventListener("load", this.contentTableHeight);
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("load", this.contentTableHeight);
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    toggleChip(index) {
      // Si el chip no está activo, actívalo; de lo contrario, déjalo como está
      if (!this.aWarehouses[index].active) {
        // Desactiva todos los chips
        this.aWarehouses.forEach((chip, i) => {
          chip.active = false;
        });
        // Cambia el estado activo del chip seleccionado
        this.selectedInOut = this.aWarehouses[index].sCity;
        this.aWarehouses[index].active = true;
      }
    },
    getWarehouses() {
      this.loadings.calculator = true;
      this.selectedWarehouse = null;

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      const warehousesPromise = DB.get(
        `${URI}/api/v1/${this.selectLanguage}/administrators/${this.$store.state.sAdministratorId}/warehouses`,
        config
      ).then((response) => {
        this.aWarehouses = response.data.results.map((obj) => {
          const {
            oWarehouse: { sName },
            oLocation: { sCity },
            sBorderWarehouseId,
          } = obj;
          this.loadings.loadingWarehouseAndPayment = false;
          if (sBorderWarehouseId == '2a9a5c8f-5806-4d00-bc92-45a14c7974ad') {
            this.selectedInOut = sCity
            return { sName, sBorderWarehouseId, active: true, sCity };
          } else {
            return { sName, sBorderWarehouseId, active: false, sCity };
          }
        });
        this.getCalculator()
      });
    },
    getCalculator() {
      let selectedWarehouse;
      this.aWarehouses.forEach((chip) => {
        if (chip.active) {
          selectedWarehouse = chip;
        }
      });
      const params = {
        sBorderWarehouseId: selectedWarehouse.sBorderWarehouseId,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.post(`${URI}/api/v1/${this.selectLanguage}/proplat-calculator/calculator`, params, config)
        .then((response) => {
          this.product = response.data.priceProduct;
          this.product.dUnitPrice = parseFloat(this.product.dUnitPrice).toFixed(2);
          this.product.dFreight = parseFloat(this.product.dFreight).toFixed(2);
          this.product.sTakeRate = parseFloat(this.product.sTakeRate).toFixed(2);
          this.product.dRatePerUSD = parseFloat(this.product.dRatePerUSD).toFixed(2);
          this.product.dFreightPercent = parseFloat(this.product.dFreightPercent).toFixed(2);
          this.product.GrossPrice = parseFloat(this.product.GrossPrice).toFixed(2);
          this.product.dInspectionCost = parseFloat(this.product.dInspectionCost).toFixed(2);
          this.product.dFinance = parseFloat(this.product.dFinance).toFixed(2);
          this.loadings.calculator = false;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
          this.loadings.calculator = false;
        });
    },
    calculatePrice() {
      if (this.$refs.myInput.validate()) {
        this.loadings.loadingFOB = true;
        const params = {
          sProplatCalculatorId: this.product.sProplatCalculatorId,
          dBoxesPerPallet: parseInt(this.product.dBoxesPerPallet),
          dFreight: parseFloat(this.product.dFreight),
          dFreightPercent: parseFloat(this.product.dFreightPercent),
          dFinance: parseFloat(this.product.dFinance),
          dInspectionCost: parseFloat(this.product.dInspectionCost),
          dRatePerUSD: parseFloat(this.product.dRatePerUSD),
          dUnitPrice: parseFloat(this.product.dUnitPrice),
          sCustomsFee: parseFloat(this.product.sCustomsFee),
          sInOutRate: parseFloat(this.product.sInOutRate),
          sTakeRate: parseFloat(this.product.sTakeRate),
          sTotalPallets: parseInt(this.product.sTotalPallets)
        };

        const config = {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
        };

        DB.patch(`${URI}/api/v1/${this.selectLanguage}/proplat-calculator/updateProduct`, params, config)
          .then((response) => {
            this.product = response.data.priceProduct;
            this.product.dUnitPrice = parseFloat(this.product.dUnitPrice).toFixed(2);
            this.product.dFreight = parseFloat(this.product.dFreight).toFixed(2);
            this.product.sTakeRate = parseFloat(this.product.sTakeRate).toFixed(2);
            this.product.dRatePerUSD = parseFloat(this.product.dRatePerUSD).toFixed(2);
            this.product.dFreightPercent = parseFloat(this.product.dFreightPercent).toFixed(2);
            this.product.GrossPrice = parseFloat(this.product.GrossPrice).toFixed(2);
            this.product.dInspectionCost = parseFloat(this.product.dInspectionCost).toFixed(2);
            this.product.dFinance = parseFloat(this.product.dFinance).toFixed(2);
            this.loadings.loadingFOB = false;
          })
          .catch((error) => {
            this.mixError(error.response.data.message, error.response.status);
            this.loadings.loadingFOB = false;
          });
      } else {
        this.mixError("Todos los campos son requeridos.", true);
        this.loadings.loadingFOB = false;
      }
    },
    deleteProduct() {
      this.loadings.loadingAddProduct = true;
      const body = {
        sCustomerId: this.selectedCustomer.sUserId,
        sProductId: this.forms.delete,
      };
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.patch(`${URI}/api/v1/${this.selectLanguage}/super-buyer-cart/delProduct`, body, config)
        .then((res) => {
          this.cancel();
          this.getTemporalShoppingCart(false);
          this.loadings.loadingAddProduct = false;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
          this.loadings.loadingAddProduct = false;
        });
    },
    formatMoney: function(money) {
      return FORMAT_MONEY(money);
    },
    cancel() {
      this.dialogAddProduct = false;
      this.dialogEditFeesPrice = false;
      this.dialogEditFinalPrice = false;
      this.dialogEditProduct = false;
      this.dialogEditExchangeRate = false;
      this.dialogDeleteProduct = false;
      this.search = "";
      this.results = [];
      this.e1 = 1;
      this.selected = [];
      this.file = null;
    },
    contentTableHeight() {
      this.heightTable = document.getElementsByClassName("tableContent");
      if (this.heightTable[0] !== undefined) {
        this.bHeightTable = this.heightTable[0].clientHeight > 760 || window.innerHeight < 800;
      }
    },
    handleResize() {
      this.contentTableHeight();
      this.screenHeight = window.innerHeight - 70;
      if (window.innerWidth > 800) {
        this.screenWidth = window.innerWidth - 100;
      } else {
        this.screenWidth = window.innerWidth;
      }
    },
  },
  computed: {
    bMenuSidebar() {
      return this.$store.state.bMenuSidebar;
    },
    selectLanguage() {
      return this.$store.state.language;
    },
    sSubcategoryId() {
      return this.$store.state.sSubcategoryId;
    },
    alphabeticallyStatus() {
      return this.$store.state.bAlphabeticallyStatus;
    },
    priceStatus() {
      return this.$store.state.bPriceStatus;
    },
  },
  watch: {
    selectLanguage() {
      if (this.selectLanguage) {
        this.texts = FILE.searchbarTexts[this.selectLanguage];
        this.getTemporalShoppingCart(false);
      }
    },
    alphabeticallyStatus: function() {
      this.iCurrentPage = 1;
    },
    priceStatus: function() {
      this.iCurrentPage = 1;
    },
  },
};
</script>
<style scoped>
.empty-image {
  width: 200px;
}
.empty-text {
  text-align: center;
  font-size: 20px;
  color: #c1c1c3;
}
.container-dialog {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  opacity: 1;
  padding: 25px;
  position: relative;
}
.text-title {
  text-align: center;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  font-size: 25px;
}
.content-shadow {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 9px;
  opacity: 1;
  padding: 20px;
}
.button-delete {
  height: 40px !important;
  text-transform: initial;
  background: transparent linear-gradient(257deg, #ffc556 0%, #fffc81 100%) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  padding-left: 35px !important;
  padding-right: 35px !important;
}
.button-cancel {
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #283c4d;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  height: 40px !important;
  text-transform: initial;
  padding-left: 35px !important;
  padding-right: 35px !important;
  background-color: #ffffff !important;
}

.v-container-search-bar {
  background-color: #f2f2f4;
  /* border-bottom: 1px solid #d4d2c0; */
  /* padding: 15px; */
  /* display: flex; */
  /* align-items: center; */
}

.titleText {
  width: 100%;
}

/* XS */
@media (max-width: 599px) {
  .v-container-search-bar {
    /* background-color: RED; */
  }
}
/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .v-container-search-bar {
    /* background-color: YELLOW; */
  }
}
/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .v-container-search-bar {
    display: flex;
    /* background-color: BLUE; */
  }
}
/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .v-container-search-bar {
    display: flex;
    /* background-color: PINK; */
  }
}
/* XL */
@media (min-width: 1904px) {
  .v-container-search-bar {
    display: flex;
    /* background-color: GREEN; */
  }
}
.gradient-button {
  background: transparent linear-gradient(228deg, #ffc556 0%, #fffc81 100%) 0% 0% no-repeat padding-box;
}
.active-chip {
  background-color: #283c4d !important; /* Cambia el color de fondo de los chips activos */
  color: #FFFFFF !important; /* Cambia el color de texto de los chips activos */
}
</style>
